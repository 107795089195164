<template>
    <!-- <div style="position: fixed; top: 0px; width: 100vw; z-index: 9"> -->
    <ion-header>
        <ion-toolbar
            style="--background: #03a9f4; color: white; --min-height: 36px"
            class="custom-toolbar"
        >
            <slot name="before">
                <ion-buttons slot="start" class="pl-2">
                    <DxButton
                        type="default"
                        icon="mdi mdi-chevron-left"
                        class="ml-1"
                        @click="$router.go(-1)"
                    />
                </ion-buttons>
            </slot>
            <slot name="center">
                <ion-title style="text-transform: uppercase">{{
                    TieuDe
                }}</ion-title>
            </slot>
            <ion-buttons slot="end">
                <slot name="after"></slot>
            </ion-buttons>
        </ion-toolbar>
    </ion-header>
</template>
<script>
import { chevronBack } from "ionicons/icons";
import {
    IonToolbar,
    IonTitle,
    IonButtons,
    IonIcon,
    IonHeader,
    IonBackButton,
    IonButton,
} from "@ionic/vue";
import { DxButton } from "devextreme-vue";
export default {
    setup() {
        return {
            chevronBack,
        };
    },
    props: {
        TieuDe: { type: String, default: "" },
        height: { type: String, default: "44px" },
    },
    components: {
        IonToolbar,
        IonButtons,
        IonTitle,
        IonIcon,
        IonHeader,
        IonBackButton,
        IonButton,
        DxButton,
    },
    data() {
        return {};
    },
    methods: {},
};
</script>
<style lang="scss" scoped>
.toolbar {
    height: 44px;
    background-color: $primary !important;
}
</style>
<style scoped>
>>> .dx-button .dx-icon {
    color: #ffffff !important;
}
>>> .custom-toolbar .toolbar-container {
    height: 48px;
}
</style>