<template>
    <ion-page data-pageid="home">
        <ion-content :scroll-events="false">
            <ChinhSuaThongTinCaNhanVue
                TieuDe="Địa chỉ thường trú"
                :model="model.DiaChiCuThe"
                keyChinhSua="diaChi"
                @ChinhSua="chinhSua"
            >
                <template #content>
                    <DxValidationGroup ref="formValidation">
                        <div>
                            <span class="font-12">Tỉnh (*)</span>
                            <DxSelectBox
                                v-model="model.Tinh"
                                :items="data.Tinh"
                                display-expr="tenTinh"
                                value-expr="id_Tinh"
                                :search-enabled="true"
                                ref="refTab1"
                                styling-mode="underlined"
                                no-data-text="Không có dữ liệu!"
                                :onItemClick="onItemClickTinh"
                                ><DxValidator>
                                    <DxRequiredRule
                                        message="Tỉnh không được để trống!"
                                    /> </DxValidator
                            ></DxSelectBox>
                        </div>
                        <div class="pt-4">
                            <span class="font-12">Quận/huyện (*)</span>
                            <DxSelectBox
                                v-model="model.QuanHuyen"
                                :items="data.QuanHuyen"
                                display-expr="tenHuyen"
                                value-expr="id_Huyen"
                                :search-enabled="true"
                                styling-mode="underlined"
                                no-data-text="Không có dữ liệu!"
                                ><DxValidator>
                                    <DxRequiredRule
                                        message="Quận/huyện không được để trống!"
                                    /> </DxValidator
                            ></DxSelectBox>
                        </div>
                        <div class="pt-4">
                            <span class="font-12">Địa chỉ cụ thể</span>
                            <DxTextBox
                                class="mb-3"
                                v-model="model.DiaChiCuThe"
                                validationMessageMode="always"
                                styling-mode="underlined"
                            ></DxTextBox>
                        </div>
                    </DxValidationGroup>
                </template>
            </ChinhSuaThongTinCaNhanVue>
            <!-- ====================================Popup Thông báo ========================================= -->
            <PopupVue
                height="38%"
                :dialog="dialogThongBao"
                title=""
                :useButtonLeft="false"
                @close="
                    () => {
                        dialogThongBao = false;
                    }
                "
            >
                <template #content
                    ><ThongBaoVue
                        :message="ParamThongBao.message"
                        :state="ParamThongBao.state"
                        :title="ParamThongBao.title"
                    />
                </template>
            </PopupVue>
        </ion-content>
    </ion-page>
</template>
<script>
import ChinhSuaThongTinCaNhanVue from "../../../src/components/_Common/ChinhSuaThongTinCaNhan";
import PopupVue from "../../../src/components/_Common/Popup";
import ThongBaoVue from "../../../src/components/_Common/ThongBao";
import TaiKhoan from "../../../class/TaiKhoan";
import {
    DxSelectBox,
    DxTextBox,
    DxValidationGroup,
    DxValidator,
} from "devextreme-vue";
import { DxRequiredRule } from "devextreme-vue/data-grid";
import { IonContent, IonPage } from "@ionic/vue";
export default {
    layout: "application",
    components: {
        ChinhSuaThongTinCaNhanVue,
        PopupVue,
        ThongBaoVue,
        DxValidationGroup,
        DxValidator,
        DxRequiredRule,
        DxSelectBox,
        DxTextBox,
        IonContent,
        IonPage,
    },
    props: {},
    data() {
        return {
            dialogThongBao: false,
            model: { DiaChiCuThe: "", Tinh: null, QuanHuyen: null },
            data: {
                Tinh: [],
                QuanHuyen: [],
            },
            ParamThongBao: {
                state: "Error",
                title: "Thông báo!",
                message: "Vui lòng kiểm tra lại",
            },
        };
    },
    methods: {
        onItemClickTinh(e) {
            this.layDanhSachHuyen(e.itemData.id_Tinh);
        },
        async layDanhSachTinh() {
            try {
                let rs = await new TaiKhoan().layDanhSachTinh();
                if (!rs) {
                    throw new Error();
                }
                if (!rs.Data.status) {
                    return this.thongBao(
                        "Error",
                        "Lỗi kết nối đến máy chủ",
                        rs.Data.message
                    );
                }
                this.data.Tinh = rs.Data.data;
                if (this.$route.query.idHuyen) {
                    this.model.QuanHuyen = this.$route.query.idHuyen;
                    this.layDanhSachHuyen(this.$route.query.idTinh);
                }
            } catch (error) {
                console.error(error);
                this.thongBaoLoi();
            }
        },
        async layDanhSachHuyen(idTinh) {
            this.data.QuanHuyen = [];
            try {
                let rs = await new TaiKhoan().layDanhSachHuyen(idTinh);
                if (!rs) {
                    throw new Error();
                }
                if (!rs.Data.status) {
                    return this.thongBao(
                        "Error",
                        "Lỗi kết nối đến máy chủ",
                        rs.Data.message
                    );
                }
                this.data.QuanHuyen = rs.Data.data;
            } catch (error) {
                console.error(error);
                this.thongBaoLoi();
            }
        },
        async chinhSua() {
            let valid = this.$refs.formValidation.instance.validate();
            if (!valid.isValid) {
                return;
            }
            try {
                let rs = await new TaiKhoan().chinhSuaDiaChiCaNhan(
                    this.model.QuanHuyen,
                    this.model.DiaChiCuThe
                );
                if (!rs) {
                    throw new Error();
                }
                if (!rs.Data.status) {
                    return this.thongBao(
                        "Error",
                        "Lỗi kết nối đến máy chủ",
                        rs.Data.message
                    );
                }
                this.$router.back();
            } catch (error) {
                console.error(error);
            }
        },
        thongBao(state, title, message) {
            this.ParamThongBao = {
                state: state,
                title: title,
                message: message,
            };
            this.dialogThongBao = true;
        },
        thongBaoLoi(message) {
            this.thongBao(
                "Error",
                message || "Đã xảy ra lỗi. Vui lòng thử lại"
            );
        },
    },
    created() {},
    mounted() {
        let refSdt = this.$refs.refTab1.instance;
        if (refSdt) {
            refSdt.focus();
        }
        this.model.Tinh = this.$route.query.idTinh;
        this.model.DiaChiCuThe = this.$route.query.diaChi;
        this.layDanhSachTinh();
    },
};
</script>
<style scoped>
</style>
<style lang='scss' scoped>
</style>